import React from 'react';
import Button from '../Button';
import uiStrings from '../../services/uiStrings';
import './Prompt.scss';

export default function Prompt({ message, onClose }) {
    return (
        <div className="alert-container">
            <p>{message}</p>
            <div className="btn-row">
                <Button onClick={() => onClose(false)} kind="secondary">{uiStrings.no}</Button>
                <Button onClick={() => onClose(true)}>{uiStrings.ok}</Button>
            </div>
        </div>
    );
}
