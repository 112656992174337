import React from 'react';
import './Button.scss';

export default function Button({
    type = 'button',
    id,
    children,
    name,
    onClick,
    className = '',
    loading,
    disabled,
    tip,
    kind = 'primary'
}) {
    const classList = ['av-btn', kind, className, (loading ? 'loading' : '')];

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            id={id}
            onClick={onClick}
            name={name}
            disabled={disabled}
            className={classList.join(' ').trim()}
            title={tip}
        >
            {loading && <span className="btn-loading-content pulsating-circle" />}
            <span className="btn-content">{children}</span>
        </button>
    );
}
