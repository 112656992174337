import api from './api';

function getOrder(orderId) {
    return api.get(`orders/${orderId}`);
}

function setOrderAsPaid(orderId, paymentId) {
    return api.patch(`orders/${orderId}`, {
        status: 'paid',
        stripePayment: {
            paymentMethodId: paymentId
        }
    });
}

function createOrder(order) {
    return api.post('orders', order);
}

function getAllOrders() {
    return api.get('boss/orders');
}

function updateOrder(orderId, order) {
    return api.put(`boss/orders/${orderId}`, order);
}

function getOrderForAdmin(orderId) {
    return api.get(`boss/orders/${orderId}`);
}

export default {
    getOrder,
    setOrderAsPaid,
    createOrder,
    admin: {
        getAllOrders,
        updateOrder,
        getOrderForAdmin
    }
};
