import api from './api';

const mapFromApi = (item) => ({
    ...item,
    created: new Date(item.created)
});

async function getAllProducts() {
    const results = await api.get('products');
    return results.map(mapFromApi);
}

async function getProductById(id) {
    const result = await api.get(`products/${id}`);
    return mapFromApi(result);
}

async function createProduct(product) {
    const data = {
        ...product,
        price: Number(product.price)
    };

    const result = await api.post('boss/products', data);
    return mapFromApi(result);
}

async function updateProduct(product) {
    const data = {
        ...product,
        price: Number(product.price)
    };

    const result = await api.put(`boss/products/${product._id}`, data);
    return mapFromApi(result);
}

async function updateProductCollection(productList) {
    const mapped = productList.map((p) => ({
        ...p,
        price: Number(p.price)
    }));

    const results = await api.put('boss/products', mapped);
    return results.map(mapFromApi);
}

function deleteProduct(id) {
    return api.del(`boss/products/${id}`);
}

export default {
    getAllProducts,
    createProduct,
    getProductById,
    updateProduct,
    updateProductCollection,
    deleteProduct
};
