import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import { CloudinaryContext } from 'cloudinary-react';
import { InventoryProvider, useInventory } from './contexts/InventoryProvider';
import NotificationProvider from './contexts/NotificationProvider';
import ClientApp from './client/ClientApp';
import AdminApp from './admin/AdminApp';
import appSettings from './appSettings';
import './services/extensions';
import './app.scss';

const initialState = {};

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function AppInternal() {
    const { actions } = useInventory();
    useEffect(() => {
        actions.getInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="site-container">
            <React.Suspense fallback={<div>loading</div>}>
                <Switch>
                    <Route path="/admin">
                        <AdminApp />
                    </Route>
                    <Route path="">
                        <ClientApp />
                    </Route>
                </Switch>
            </React.Suspense>
        </div>
    );
}

export default function App() {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://hawkbone.com" />
                <meta name="application-name" content="Hawkbone" />
                <meta property="og:title" content="Hawkbone" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="https://hawkbone.com" />
                <meta name="twitter:title" content="Hawkbone" />
                <meta name="twitter:description" content="" />
                <meta name="twitter:image" content="" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://hawkbone.com" />
            </Helmet>
            <NotificationProvider>
                <InventoryProvider initialState={initialState}>
                    <CloudinaryContext cloudName={appSettings.cloudinaryCloudName}>
                        <Router>
                            <ScrollToTop />
                            <AppInternal />
                        </Router>
                    </CloudinaryContext>
                </InventoryProvider>
            </NotificationProvider>
        </>
    );
}
