import React from 'react';
import Button from '../Button';
import uiStrings from '../../services/uiStrings';
import './Alert.scss';

export default function Alert({ message, level = 'error', onClose }) {
    const iconClass = level === 'error' ? 'fa-exclamation-triangle' : '';

    return (
        <div className="alert-container">
            <span className={`fas ${iconClass}`} />
            <p>{message}</p>
            <Button onClick={onClose}>{uiStrings.ok}</Button>
        </div>
    );
}
