import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../../shared/Button';
import auth0Client from '../../services/auth';
import uiStrings from '../../services/uiStrings';

function Callback({ history }) {
    useEffect(() => {
        (async function () {
            await auth0Client.handleAuthentication();
            history.replace('/admin');
        }());
    }, [history]);

    return (
        <>
            <p>{uiStrings.loadingProfile}</p>
            <Button onClick={auth0Client.signOut}>{uiStrings.signOut}</Button>
        </>
    );
}

export default withRouter(Callback);
