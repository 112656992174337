import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Nav.scss';

export default function Nav({ items }) {
    const location = useLocation();

    return (
        <nav className="nav-container">
            {items.map((item) => (
                <div className={`nav-item ${location.pathname === item.path ? 'selected' : null}`} key={item.path}>
                    <Link to={`${item.path}`}>{item.name.toUpperCase()}</Link>
                </div>
            ))}
        </nav>
    );
}
