import React, { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TextInput from '../../shared/TextInput';

export default function ShopSearchInput() {
    const location = useLocation();
    const history = useHistory();

    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const handleInputChange = useCallback(({ target }) => {
        const { value } = target;

        query.set('q', value);

        const action = history.location.pathname === '/shop' ? history.replace : history.push;
        action({
            pathname: '/shop',
            search: `?${query.toString()}`
        });
    }, [query, history]);

    const handleClearSearch = useCallback(() => {
        query.delete('q');
        history.push({
            pathname: '/shop',
            search: `?${query.toString()}`
        });
    }, [query, history]);

    const value = query.get('q');
    return (
        <TextInput
            value={value || ''}
            onChange={handleInputChange}
            groupClassName="shop-search-input-container"
            decorator={!value
                ? <span className="fas fa-search" aria-hidden="false" />
                : (
                    <span
                        className="fas fa-times"
                        aria-label="clear input"
                        role="button"
                        tabIndex={-1}
                        onClick={handleClearSearch}
                        onKeyDown={() => {}}
                    />
                )}
        />
    );
}
