import api from './api';

function getCart() {
    return api.get('cart');
}

function updateCart(cart) {
    return api.post('cart', cart);
}

export default {
    getCart,
    updateCart
};
