import auth0 from 'auth0-js';
import appSettings from '../appSettings';

class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            // the following three lines MUST be updated
            domain: appSettings.auth0Domain,
            audience: appSettings.auth0AudienceUrl,
            clientID: appSettings.auth0ClientId,
            redirectUri: appSettings.auth0CallbackUrl,
            responseType: 'id_token token',
            scope: 'openid profile'
        });
    }

    getProfile = () => this.profile;

    getIdToken = () => this.idToken;

    isAuthenticated = () => new Date().getTime() < this.expiresAt;

    signIn = () => {
        this.auth0.authorize();
    }

    handleAuthentication = () => new Promise((resolve, reject) => {
        this.auth0.parseHash((err, authResult) => {
            if (err) return reject(err);
            if (!authResult || !authResult.idToken) {
                return reject(err);
            }

            this.setSession(authResult);
            return resolve();
        });
    });

    setSession = (authResult) => {
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
    };

    signOut = () => {
        this.auth0.logout({
            returnTo: appSettings.baseUrl,
            responseType: 'token',
            clientId: appSettings.auth0ClientId
        });
    };

    silentAuth = () => new Promise((resolve, reject) => {
        this.auth0.checkSession({}, (err, authResult) => {
            if (err) return reject(err);
            this.setSession(authResult);
            return resolve();
        });
    });
}

const auth0Client = new Auth();

export default auth0Client;
