import React, { useContext, useCallback } from 'react';
import Alert from '../shared/Alert';
import Prompt from '../shared/Prompt';
import useModal from '../hooks/useModal';

const NotificationContext = React.createContext({});

export default function NotificationProvider({ children }) {
    const { ModalRoot, openModal } = useModal();

    const showAlert = useCallback((message, level) => openModal(Alert, { message, level }, 'alert'), [openModal]);
    const showPrompt = useCallback((message) => openModal(Prompt, { message }, 'prompt'), [openModal]);

    return (
        <NotificationContext.Provider value={{ showAlert, showPrompt }}>
            {children}
            <ModalRoot />
        </NotificationContext.Provider>
    );
}

export function useNotifications() {
    return useContext(NotificationContext);
}
