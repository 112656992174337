import React, { useContext, useReducer, useMemo } from 'react';
import ordersApi from '../services/api/ordersApi';

function createGetOrdersAction(state, dispatch) {
    return async () => {
        const results = await ordersApi.admin.getAllOrders();
        dispatch({ type: 'SET_ORDERS', payload: results });
    };
}

function adminReducer(state, action) {
    switch (action.type) {
        case 'SET_ORDERS':
            return {
                ...state,
                orders: action.payload,
                ordersLoaded: true
            };
        case 'UPDATE_ORDER':
            return {
                ...state,
                orders: state.orders.map((o) => {
                    if (o._id === action.payload._id) return action.payload;
                    return o;
                })
            };
        default:
            return state;
    }
}

const AdminContext = React.createContext({});

export function AdminProvider({ children, initialState = {} }) {
    const [state, dispatch] = useReducer(adminReducer, {
        ordersLoaded: false,
        orders: [],
        ...initialState
    });
    const context = useMemo(() => ({
        ...state,
        actions: {
            getOrders: createGetOrdersAction(state, dispatch)
        }
    }), [state, dispatch]);

    return (
        <AdminContext.Provider value={context}>
            {children}
        </AdminContext.Provider>
    );
}

export function useAdmin() {
    return useContext(AdminContext);
}
