import React, { useContext, useReducer, useMemo } from 'react';
import productsApi from '../services/api/productsApi';
import cartApi from '../services/api/cartApi';

function inventoryReducer(state, action) {
    switch (action.type) {
        case 'SET_INVENTORY':
            return {
                ...state,
                inventory: action.payload || [],
                inventoryLoaded: true
            };
        case 'ADD_PRODUCT':
            return { ...state, inventory: [...state.inventory, action.payload] };
        case 'REMOVE_PRODUCT':
            return {
                ...state,
                inventory: state.inventory.filter((item) => action.payload._id !== item._id)
            };
        case 'UPDATE_PRODUCTS':
            return {
                ...state,
                inventory: state.inventory.map((item) => {
                    const found = action.payload.find((i) => i._id === item._id);
                    if (found) return found;
                    return item;
                })
            };
        case 'SET_CART':
            return { ...state, cart: action.payload };
        case 'REMOVE_FROM_CART':
            return { ...state, cart: state.cart.filter((item) => item._id !== action.payload._id) };
        default:
            return state;
    }
}

function createGetCartAction(state, dispatch) {
    return async () => {
        const result = await cartApi.getCart();
        dispatch({ type: 'SET_CART', payload: result.items });
    };
}

function createClearCartAction(state, dispatch) {
    return async () => {
        await cartApi.updateCart([]);
        dispatch({ type: 'SET_CART', payload: [] });
    };
}

function createAddToCartAction(state, dispatch) {
    return async (item) => {
        const newCart = [...state.cart, item._id];

        await cartApi.updateCart(newCart);
        dispatch({ type: 'SET_CART', payload: newCart });
    };
}

function createRemoveFromCartAction(state, dispatch) {
    return async (item) => {
        const newCart = state.cart.filter((id) => id !== item._id);
        await cartApi.updateCart(newCart);
        dispatch({ type: 'SET_CART', payload: newCart });
    };
}

function createCheckoutAction(state, dispatch) {
    return async () => {
        const forUpdate = state.cart.map((i) => ({ ...i, status: 'sold' }));
        dispatch({ type: 'UPDATE_PRODUCTS', payload: forUpdate });
        dispatch({ type: 'CLEAR_CART' });
    };
}

function createGetInventoryAction(state, dispatch) {
    return async () => {
        const result = await productsApi.getAllProducts();
        dispatch({ type: 'SET_INVENTORY', payload: result });
    };
}

function createRemoveProductAction(state, dispatch) {
    return async (item) => {
        await productsApi.deleteProduct(item._id);
        dispatch({ type: 'REMOVE_PRODUCT', payload: item });
    };
}

function createCreateProductAction(state, dispatch) {
    return async (product) => {
        const result = await productsApi.createProduct(product);
        dispatch({ type: 'ADD_PRODUCT', payload: result });
        return result;
    };
}

function createUpdateProductAction(state, dispatch) {
    return async (product) => {
        const result = await productsApi.updateProduct(product);
        dispatch({ type: 'UPDATE_PRODUCTS', payload: [{ ...result }] });
    };
}

function createUpdateProductCollectionAction(state, dispatch) {
    return async (list) => {
        try {
            dispatch({ type: 'SET_INVENTORY', payload: list });
            await productsApi.updateProductCollection(list);
        } catch (err) {
            createGetInventoryAction(state, dispatch)();
        }
    };
}

const InventoryContext = React.createContext({});

export function InventoryProvider({ children, initialState = {} }) {
    const [state, dispatch] = useReducer(inventoryReducer, {
        inventoryLoaded: false,
        inventory: [],
        cart: [],
        ...initialState
    });
    const context = useMemo(() => ({
        ...state,
        actions: {
            getInventory: createGetInventoryAction(state, dispatch),
            removeProduct: createRemoveProductAction(state, dispatch),
            createProduct: createCreateProductAction(state, dispatch),
            updateProduct: createUpdateProductAction(state, dispatch),
            updateProductCollection: createUpdateProductCollectionAction(state, dispatch),
            getCart: createGetCartAction(state, dispatch),
            clearCart: createClearCartAction(state, dispatch),
            addToCart: createAddToCartAction(state, dispatch),
            removeFromCart: createRemoveFromCartAction(state, dispatch),
            checkout: createCheckoutAction(state, dispatch)
        }
    }), [state, dispatch]);

    return (
        <InventoryContext.Provider value={context}>
            {children}
        </InventoryContext.Provider>
    );
}

export function useInventory() {
    return useContext(InventoryContext);
}
