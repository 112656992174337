import { useState, useEffect } from 'react';

function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(this, args);
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, Number(wait));

        if (callNow) func.apply(this, args);
    };
}

export default function useSize() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeFunc = debounce(() => {
            setWindowWidth(window.innerWidth);
        }, 500);

        window.addEventListener('resize', resizeFunc);
        return () => window.removeEventListener('resize', resizeFunc);
    }, []);

    return {
        isExtraSmall: windowWidth < 768,
        isSmall: windowWidth >= 768 && windowWidth < 993,
        isMedium: windowWidth > 993 && windowWidth < 1200,
        isLarge: windowWidth > 1200,
        isPortrait: windowWidth <= window.innerHeight
    };
}
