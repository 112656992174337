import React, { useEffect, useState } from 'react';
import {
    Route,
    useRouteMatch,
    withRouter
} from 'react-router-dom';
import { adminRoutes } from '../routes';
import auth0Client from '../services/auth';
import Callback from './components/Callback';
import AdminNav from './components/AdminNav';
import { AdminProvider } from '../contexts/AdminProvider';

const initialState = {};

function SecuredPage({
    path,
    component: View,
    title,
    checkingSession
}) {
    const match = useRouteMatch();

    useEffect(() => {
        if (title) {
            document.title = `${title}  --`;
        }
    }, [title]);

    return (
        <Route
            exact
            path={`${match.url}${path}`}
            render={() => {
                if (checkingSession) return <h3 className="text-center">Validating session...</h3>;
                if (!auth0Client.isAuthenticated()) {
                    auth0Client.signIn();
                    return <div />;
                }

                return <View />;
            }}
        />
    );
}

function App({ location }) {
    const [checkingSession, setCheckingSession] = useState(true);

    useEffect(() => {
        (async function () {
            if (location.pathname === '/admin/callback') {
                setCheckingSession(false);
                return;
            }

            try {
                await auth0Client.silentAuth();
            } catch (err) {
                if (err.error !== 'login_required') console.error(err.error);
            }
            setCheckingSession(false);
        }());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminProvider initialState={initialState}>
            <div className="content-container admin">
                <AdminNav />
                <React.Suspense fallback={<div>loading</div>}>
                    <Route exact path="/admin/callback" component={Callback} />
                    {adminRoutes.map((route) => <SecuredPage {...route} key={route.path} checkingSession={checkingSession} />)}
                </React.Suspense>
            </div>
        </AdminProvider>
    );
}

export default withRouter(App);
