export default {
    _formatString: (template, ...args) => template.replace(/\{\s*([^}\s]+)\s*\}/g, (m, p1) => args[p1]),
    about: 'About',
    aboutMessage: 'Hawkbone brings you rustic vintage smalls. Inspired by a love for the American West, it’s the rugged and rural places that we daydream about. And it’s the objects from those places that we seek. Good designers knows it’s the last layer that pulls it all together. Accessories elevate and personalize our space. We relate more intimately to objects with a human scale and especially so when they bear the patina of time. Vintage smalls evoke feelings and tell stories in a way the new cannot. Find your story here.',
    accept: 'Accept',
    addAccount: 'Add Account',
    addProduct: 'Add Product',
    address: 'Address',
    addressSecondary: 'Apartment, suite, etc.',
    addToCart: 'ADD TO CART',
    addTags: 'Add Tags',
    admin: 'Admin',
    all: 'All',
    alreadyTheCoverImage: 'Already the cover image',
    alreadyInCart: 'ALREADY IN CART',
    archived: 'Archived',
    areYouSure: 'Are you sure?',
    available: 'Available',
    back: 'Back',
    backToInventory: 'Back to Inventory',
    backToOrders: 'Back to Orders',
    cancel: 'Cancel',
    cardNumber: 'Card Number',
    cart: 'Cart',
    checkout: 'Checkout',
    circular: 'Circular',
    city: 'City',
    collecting: 'Collecting',
    confirmReturn: 'Are you sure you want to set order as Returned?',
    condition: 'Condition',
    contact: 'Contact',
    contactMessage: 'Please message me with any questions or comments, for additional item details, whatever. I\'ll be happy to assist and any way I can. Based in Los Angeles.',
    continueShopping: 'Continue Shopping',
    cookiePolicy: 'Cookie Policy',
    country: 'Country',
    create: 'Create',
    createProduct: 'Create Product',
    company: 'Company',
    count: 'Count: ',
    cvcNumber: 'CVC Number',
    delete: 'Delete',
    description: 'Description',
    depth: 'Depth',
    details: 'Details',
    diameter: 'Diameter',
    dimensions: 'Dimensions',
    dragToReorder: 'Drag and drop items to reorder product list',
    edit: 'Edit',
    editProduct: 'Edit Product',
    email: 'Email',
    emptyCartMessage: 'You have nothing in your shopping cart.',
    emptyTradeAccountListMessage: 'You\'ve Not Added Any Accounts Yet',
    enterEmailAddress: 'Enter Email Address',
    everything: 'Everything',
    expirationDate: 'Expiration Date',
    firstName: 'First Name',
    gdprCookieMessage: 'Hawkbone uses cookies to provide necessary site functionality and improve your experience. By using our website, you agree to our {0}.',
    grid: 'Grid',
    goToCart: 'Go To Cart',
    height: 'Height',
    home: 'Home',
    hold: 'Hold',
    images: 'Images',
    inches: 'inches',
    initiated: 'Initiated',
    inventory: 'Inventory',
    inquire: 'Inquire',
    item: 'item',
    items: 'items',
    lastName: 'Last Name',
    length: 'Length',
    list: 'List',
    loadingProfile: 'Loading Profile...',
    makePayment: 'Make Payment',
    message: 'Message',
    name: 'Name',
    no: 'No',
    noImages: 'No Images Yet',
    notYetShipped: 'Not Yet Shipped',
    addImage: 'Add Image',
    ok: 'OK',
    optional: 'optional',
    order: 'Order',
    orders: 'Orders',
    orderComplete: 'Order Complete',
    orderDetails: 'Order Details',
    orderItems: 'Order Items',
    orderId: 'Order Id',
    orderShippingNotice: 'Orders usual shipping within three to five business days. We\'ll send you an email once we\'ve sent it out.',
    payment: 'Payment',
    paymentDetails: 'Payment Details',
    pounds: 'lbs',
    paid: 'Paid',
    price: 'price',
    primary: 'Primary',
    privacyPolicy: 'Privacy Policy',
    productName: 'Product Name',
    productId: 'Product Id',
    rectangular: 'Rectangular',
    recentlyViewed: 'Recently Viewed',
    reject: 'Reject',
    returned: 'Returned',
    returnPolicy: 'Return Policy',
    returnPolicyMessage: 'Coming soon...',
    returnToShop: 'Return To Shop',
    salesTax: 'Sales Tax',
    search: 'Search',
    setAsShippedErrorMessage: 'Cannot set to shipped without a tracking number',
    shipped: 'Shipped',
    shipping: 'Shipping',
    shippingDamages: 'Shipping Damages',
    shippingOptions: 'Shipping Options',
    shippingMethod: 'Shipping Method',
    shipTo: 'Ship To',
    shippingTo: 'Shipping To',
    shop: 'Shop',
    signOut: 'Sign Out',
    sold: 'Sold',
    setAsCoverImage: 'Make Cover Image',
    state: 'State/Province/Region',
    stripeId: 'Stripe Id',
    submit: 'Submit',
    submitInquiry: 'Submit Inquiry',
    subTotal: 'Subtotal',
    tags: 'Tags',
    thanksForTheOrder: 'Thanks for your Order!',
    thisWebsiteUsesCookies: 'This website uses cookies',
    toPayment: 'To Payment',
    toShipping: 'To Shipping',
    total: 'Total',
    trackingNumber: 'Tracking Number',
    tradeAccounts: 'Trade Accounts',
    trade: 'Trade',
    update: 'Update',
    upload: 'Upload',
    view: 'View',
    weight: 'Weight',
    whatsNew: 'What\'s New',
    width: 'Width',
    youMightAlsoLike: 'You Might Also Like',
    yourOrder: 'Your Order',
    zipCode: 'ZIP code'
};
