import React from 'react';
import { useLocation } from 'react-router-dom';
import Nav from './Nav';
import CartFloater from './CartFloater';
import ShopSearchInput from './ShopSearchInput';
import Banner from './Banner';
import uiStrings from '../../services/uiStrings';
import useSize from '../../hooks/useSize';

const navItems = [
    { name: uiStrings.home, path: '/shop?type=all' },
    { name: uiStrings.trade, path: '/trade' },
    { name: uiStrings.about, path: '/about' },
    { name: uiStrings.contact, path: '/contact' }
];

const bannerPaths = [
    '/', '/shop'
];

export default function Header() {
    const size = useSize();
    const location = useLocation();

    return (
        <header>
            <div className="header-top">
                <a href="/"><h2>hawkbone</h2></a>
                <div className="spacer" />
                {!size.isExtraSmall && <ShopSearchInput />}
                <CartFloater />
            </div>
            {size.isExtraSmall && <ShopSearchInput />}
            {bannerPaths.includes(location.pathname) && (
                <Banner />
            )}
            <Nav items={navItems} />
        </header>
    );
}
