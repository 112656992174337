import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../../shared/Button';
import auth from '../../../services/auth';
import uiStrings from '../../../services/uiStrings';
import './AdminNav.scss';

function AdminNavLink({ to, label, location }) {
    return (
        <div className={`nav-item ${location.pathname === to ? 'selected' : null}`}>
            <Link to={to} className="av-btn" title={label}>
                {label}
            </Link>
        </div>
    );
}

export default function AdminNav() {
    const location = useLocation();

    return (
        <div className="admin-page-nav">
            <div className="nav-header">
                <Link to="/" className="av-btn" title={uiStrings.returnToShop}>
                    <span className="fas fa-store-alt" aria-label={uiStrings.returnToShop} aria-hidden="true" />
                </Link>
                <Button id="sign-out-btn" type="button" onClick={auth.signOut} tip={uiStrings.signOut}>
                    <span className="fas fa-sign-out-alt" aria-label={uiStrings.signOut} aria-hidden="true" />
                </Button>
            </div>
            <div className="nav-page-links">
                <AdminNavLink to="/admin/inventory" label={uiStrings.inventory} location={location} />
                <AdminNavLink to="/admin/orders" label={uiStrings.orders} location={location} />
                <AdminNavLink to="/admin/trade" label={uiStrings.tradeAccounts} location={location} />
            </div>
        </div>
    );
}
