import api from './api';

function createInquiry({ email, msg, productId }) {
    return api.post('emails/inquiry', { from: email, msg, productId });
}

function createTrackingUpdate(orderId, trackingNumber) {
    return api.post('emails/trackingUpdate', { orderId, trackingNumber });
}

function addContact(emailAddress) {
    return api.post('emails/contact', { emailAddress });
}

export default {
    createInquiry,
    createTrackingUpdate,
    addContact
};
