import React, { useEffect } from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import PageLoader from './components/PageLoader';
import GdprCookieMessage from './components/GdprCookieMessage';
import { clientRoutes } from '../routes';
import { useInventory } from '../contexts/InventoryProvider';
import './ClientApp.scss';

function Page({ path, component: View, title }) {
    useEffect(() => {
        if (title) {
            document.title = `${title} | Hawkbone`;
        }
    }, [title]);

    return (
        <Route key={path} path={path} component={View} />
    );
}

export default function App() {
    const { actions } = useInventory();
    useEffect(() => {
        actions.getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="client-app-container">
            <Header />
            <div className="content-container">
                <React.Suspense fallback={<PageLoader />}>
                    <Switch>
                        {clientRoutes.map((route) => <Page {...route} key={route.path} />)}
                    </Switch>
                </React.Suspense>
                <Footer />
            </div>
            <GdprCookieMessage />
        </div>
    );
}
