const config = {
    auth0AudienceUrl: process.env.auth0AudienceUrl,
    auth0ClientId: process.env.auth0ClientId,
    auth0CallbackUrl: process.env.auth0CallbackUrl,
    auth0Domain: process.env.auth0Domain,
    baseUrl: process.env.baseUrl,
    apiBaseUrl: process.env.apiBaseUrl,
    apiKey: process.env.apiKey,
    cloudinaryCloudName: process.env.cloudinaryCloudName,
    environment: process.env.NODE_ENV,
    stripePublicKey: process.env.stripePublicKey
};

export default config;
