import React, { useState, useEffect } from 'react';
import home1 from '../../../img/HOME_emily-hoehenrieder-hIPq_bVj8gE-unsplash.jpg';
import home2 from '../../../img/HOME_lili-kovac-BSQq5dRT_KU-unsplash.jpg';
import './Banner.scss';

const images = [
    { img: home1, alt: 'By Emily Hoehenrieder' },
    { img: home2, alt: 'By Lili Kovac' }
];

export default function Banner() {
    const [imageIndex, setIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((curr) => {
                let index = curr + 1;
                if (index === images.length) index = 0;
                return index;
            });
        }, 5000);

        return () => clearInterval(interval);
    }, [imageIndex]);

    return (
        <div className="site-banner">
            <img src={images[imageIndex].img} alt={images[imageIndex].alt} />
        </div>
    );
}
