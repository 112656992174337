import React, { useState, useCallback } from 'react';
import Nav from './Nav';
import TextInput from '../../shared/TextInput';
import Button from '../../shared/Button';
import uiStrings from '../../services/uiStrings';
import emailsApi from '../../services/api/emailsApi';

const navItems = [
    { name: uiStrings.returnPolicy, path: '/returns' },
    { name: uiStrings.contact, path: '/contact' },
    { name: uiStrings.about, path: '/about' }
];

function EmailListForm() {
    const [emailAddress, setEmailAddress] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const handleInputChange = useCallback(({ target }) => {
        const { value } = target;
        setEmailAddress(value);
    }, []);

    const handleSubmit = useCallback(async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (!emailAddress) return;

        setSubmitting(true);
        try {
            await emailsApi.addContact(emailAddress);
        } catch (err) {
            console.error(err);
        }

        setSubmitting(false);
        setEmailAddress('');
    }, [emailAddress]);

    return (
        <form className="email-list-form" onSubmit={handleSubmit}>
            <TextInput onChange={handleInputChange} value={emailAddress} placeholder="Join Our Email List" />
            <Button id="email-contact-submit-btn" loading={submitting} type="submit">{uiStrings.submit}</Button>
        </form>
    );
}

export default function Footer() {
    return (
        <footer>
            <Nav items={navItems} />
            <div className="sub-footer">
                <span className="copyright">© Hawkbone 2020. ALL RIGHTS RESERVED.</span>
                <EmailListForm />
                <a href="http://www.instagram.com/hawkbonehome"><span className="fab fa-instagram" aria-label="instagram" /></a>
            </div>
        </footer>
    );
}
