import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useInventory } from '../../contexts/InventoryProvider';
import uiStrings from '../../services/uiStrings';
import './CartFloater.scss';

export default function CartFloater() {
    const { cart, inventory } = useInventory();
    const cartItems = useMemo(() => inventory.filter((item) => cart.includes(item._id)), [cart, inventory]);
    const subTotal = useMemo(() => cartItems.reduce((acc, item) => acc + item.price, 0), [cartItems]);

    return (
        <div className="cart-floater">
            <Link to="/cart">
                <span className="fas fa-shopping-cart" />&nbsp;
                <div className="cart-count">{cart.length}&nbsp;{cart.length > 1 ? uiStrings.items : uiStrings.item}</div>
                <div className="cart-subtotal">${subTotal.toFixed(2)}</div>
            </Link>
        </div>
    );
}
