import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../shared/Button';
import uiStrings from '../../services/uiStrings';

function FormattedMessage({ message, fields }) {
    const output = [];
    let accumulator = '';
    for (let i = 0; i < message.length; i++) {
        if (message[i] === '{') {
            output.push(accumulator);
            accumulator = '';

            const index = Number(message[++i]);
            output.push(fields[index]);
            i++;
        } else {
            accumulator += message[i];
        }
    }

    if (accumulator) output.push(accumulator);

    return output;
}

export default function GdprCookieMessage() {
    const { gdprCookieResponse } = localStorage;
    if (!gdprCookieResponse) return null;

    return (
        <div className="gdpr-message">
            <div>
                <p>
                    <FormattedMessage
                        message={uiStrings.gdprCookieMessage}
                        fields={[
                            <Link
                                to="/privacy-policy"
                                id="privacy-policy-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {uiStrings.privacyPolicy}
                            </Link>
                        ]}
                    />
                </p>
                <div className="cookie-accept-buttons">
                    <Button>{uiStrings.accept}</Button>
                </div>
            </div>
        </div>
    );
}
