/* eslint-disable no-extend-native */
Array.prototype.orderBy = function (func, reverse = false) {
    return this.sort(
        (a, b) => {
            const valA = func(a);
            const valB = func(b);
            if (valA < valB) return reverse ? 1 : -1;
            if (valA > valB) return reverse ? -1 : 1;
            return 0;
        }
    );
};
