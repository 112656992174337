import axios from 'axios';
import auth from '../auth';
import appSettings from '../../appSettings';

export class API {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    formatUrl(url) {
        let output = `${this.baseUrl}/api/${url}`;

        if (appSettings.apiKey) {
            if (output.includes('?')) {
                output = `${output}&code=${appSettings.apiKey}&clientId=default`;
            }

            output = `${output}?code=${appSettings.apiKey}&clientId=default`;
        }

        return output;
    }

    async request({
        method, url, data, options
    }) {
        try {
            const config = {
                method,
                url: this.formatUrl(url),
                headers: {
                    'content-type': 'application/json; charset=utf-8'
                },
                withCredentials: true,
                ...options
            };
            config[(method === 'get' ? 'params' : 'data')] = data;

            const token = auth.getIdToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            const response = await axios(config);
            return response.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    get = (url, data) => this.request({ method: 'get', url, data });

    put = (url, data) => this.request({ method: 'put', url, data });

    post = (url, data) => this.request({ method: 'post', url, data });

    patch = (url, data) => this.request({ method: 'patch', url, data });

    del = (url, data) => this.request({ method: 'delete', url, data });
}

export default new API(appSettings.apiBaseUrl);
