import React from 'react';
import './TextInput.scss';

export default function TextInput({
    value,
    id,
    name,
    onChange,
    placeholder,
    required,
    label,
    type = 'text',
    error,
    decorator = null,
    groupClassName = '',
    ...rest
}) {
    const groupClassPieces = ['av-form-group', groupClassName];
    const classPieces = ['av-form-control', (error ? 'error' : ''), (decorator ? 'decorated' : '')];
    return (
        <div className={groupClassPieces.join(' ').trim()}>
            {label && <label htmlFor={id}>{label}</label>}
            <input
                id={id}
                name={name}
                className={classPieces.join(' ').trim()}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                required={required}
                type={type}
                {...rest}
            />
            {decorator && <span className="av-form-control-decorator">{decorator}</span>}
        </div>
    );
}
