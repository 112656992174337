import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.6)';
Modal.defaultStyles.overlay.zIndex = '1111';
Modal.defaultStyles.overlay.justifyContent = 'center';
Modal.defaultStyles.overlay.alignItems = 'center';
Modal.defaultStyles.overlay.display = 'flex';

Modal.defaultStyles.content.backgroundColor = 'transparent';
Modal.defaultStyles.content.position = 'relative';
Modal.defaultStyles.content.left = '0';
Modal.defaultStyles.content.top = '0';
Modal.defaultStyles.content.bottom = '0';
Modal.defaultStyles.content.right = '0';
Modal.defaultStyles.content.border = 'none';

Modal.setAppElement('body');

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeModal, setActiveModal] = useState({});

    const modalRoot = useCallback(() => {
        const {
            component: ModalComponent,
            props: modalProps,
            handleClose,
            type
        } = activeModal;
        if (ModalComponent == null) return null;

        const customStyles = { content: {} };
        if (ModalComponent.width) customStyles.content.width = ModalComponent.width;

        return (
            <Modal isOpen={isOpen} style={customStyles} closeTimeoutMS={300}>
                <div id={`av-dialog.${type}`} className={`av-dialog ${type} ${ModalComponent.className || ''}`.trimRight()}>
                    <button className="av-dialog-close" id="dialog-close-btn" type="button" onClick={() => handleClose(null)}><span>&#10006;</span></button>
                    <ModalComponent {...modalProps} onClose={handleClose} />
                </div>
            </Modal>
        );
    }, [isOpen, activeModal]);

    const openModal = useCallback((component, componentProps, type = 'creator-editor') => new Promise((resolve) => {
        const modal = {
            component,
            props: componentProps,
            type,
            handleClose: (result) => {
                setIsOpen(false);
                setTimeout(() => {
                    setActiveModal({});
                    resolve(result);
                }, 100);
            }
        };

        setActiveModal(modal);
        setTimeout(() => setIsOpen(true), 100);
    }), []);

    const closeModal = useCallback(() => {
        activeModal.handleClose(null);
    }, [activeModal]);

    return {
        ModalRoot: modalRoot,
        openModal,
        closeModal
    };
};

export default useModal;
