import React from 'react';
import uiStrings from './services/uiStrings';

function RouteModel(path, title, componentFunc) {
    this.path = path;
    this.title = title;
    this.component = React.lazy(componentFunc);
}

export const clientRoutes = [
    new RouteModel('/about', uiStrings.about, () => import('./client/components/pages/About')),
    new RouteModel('/cart', uiStrings.cart, () => import('./client/components/pages/Cart')),
    new RouteModel('/checkout', uiStrings.checkout, () => import('./client/components/pages/Checkout')),
    new RouteModel('/contact', uiStrings.contact, () => import('./client/components/pages/Contact')),
    new RouteModel('/order/:orderId', uiStrings.order, () => import('./client/components/pages/Order')),
    new RouteModel('/privacy-policy', uiStrings.privacyPolicy, () => import('./client/components/pages/PrivacyPolicy')),
    new RouteModel('/returns', uiStrings.returnPolicy, () => import('./client/components/pages/Returns')),
    new RouteModel('/shop/:itemId', '', () => import('./client/components/pages/Product')),
    new RouteModel('/shop', uiStrings.shop, () => import('./client/components/pages/Shop')),
    new RouteModel('/trade', uiStrings.trade, () => import('./client/components/pages/Trade')),
    new RouteModel('', uiStrings.home, () => import('./client/components/pages/Shop'))
];

export const adminRoutes = [
    new RouteModel('/add', uiStrings.admin, () => import('./admin/components/InventoryForm')),
    new RouteModel('/inventory/:itemId', uiStrings.admin, () => import('./admin/components/InventoryForm')),
    new RouteModel('/inventory', uiStrings.admin, () => import('./admin/components/InventoryManagement')),
    new RouteModel('/orders/:orderId', uiStrings.admin, () => import('./admin/components/OrderDetails')),
    new RouteModel('/orders', uiStrings.admin, () => import('./admin/components/OrderManagement')),
    new RouteModel('/trade', uiStrings.admin, () => import('./admin/components/TradeAccountManagement')),
    new RouteModel('', uiStrings.admin, () => import('./admin/components/InventoryManagement'))
];
